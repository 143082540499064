<template>
  <div id="app">
    <router-view />
    <div id="footer" class="footer"></div>

  </div>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      itemsSalir: [
        {
          label: "Configuracion",
          icon: "pi pi-fw pi-cog",
          command: (event) => {
            this.configuracion();
          },
        },
        {
          separator: true,
        },
        {
          label: "Salir",
          icon: "pi pi-fw pi-power-off",
          command: (event) => {
            this.salir();
          },
        },
      ],
    }
  },
  methods: {
    async salir() {
      this.loading = true;
      this.loadingMensaje = "Cerrando Sesión...";
      let respuesta;
      try {
        respuesta = await axios.put(
          "https://api-framework.contago.com.mx/api/Usuarios/actualizaSesion/" +
          this.$store.state.usuario.nombre +
          "/INACTIVA"
        );
        this.$store.dispatch("salir");

        //INICIALIZAMOS LOS CATALOGOS
        let correo = {
          asunto: '',
          mensaje: '',
          emisor: {},
          archivos: [],
          receptores: [],
        }
        let empresa = {
          logo: {
            extencion: '',
            base64: '',
          },
          idEmpresa: 0,
          rfc: '',
          domicilioFiscal: '',
          nombre: '',
          regimenFiscal: {}
        }

        this.$store.state.empresaStore = { ...empresa }
        this.$store.state.correoStore = { ...correo }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
  },
  created() {
    this.$store.dispatch("autoLogin");

  },
  computed: {

    logueado() {
      return this.$store.state.usuario;
    },
    obtieneEmpresa() {
      return this.$store.state.usuario.empresa;
    },
    obtieneUsuario() {
      return this.$store.state.usuario.nombre;
    },
    Usuario() {
      return this.$store.state.usuario.rol;
    },

  },


}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.border {
  /* border-top: 3px; */
  background-color: #E64747;
  height: 60px;
}
</style>
