import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import PrimeVue from 'primevue/config';

import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import InputMask from 'primevue/inputmask';
import Calendar from 'primevue/calendar';
import AutoComplete from 'primevue/autocomplete';
import InputNumber from 'primevue/inputnumber';
import Card from 'primevue/card';
import Button from 'primevue/button';
import Divider from 'primevue/divider';
import Dialog from 'primevue/dialog';
import ProgressBar from 'primevue/progressbar';
import Toast from 'primevue/toast';
import OverlayPanel from 'primevue/overlaypanel';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Menubar from 'primevue/menubar';
import Toolbar from 'primevue/toolbar';
import Avatar from 'primevue/avatar';
import TieredMenu from 'primevue/tieredmenu';
import Menu from 'primevue/menu';
import ImagePreview from 'primevue/imagepreview';
import Password from 'primevue/password';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import RadioButton from 'primevue/radiobutton';
import Row from 'primevue/row';
import Tooltip from 'primevue/tooltip';
import MegaMenu from 'primevue/megamenu';
import FileUpload from 'primevue/fileupload';
import SelectButton from 'primevue/selectbutton';
import InputSwitch from 'primevue/inputswitch';
import MultiSelect from 'primevue/multiselect';
import OtpInput from "@bachdgvn/vue-otp-input";
import Chip from 'primevue/chip';
import Chips from 'primevue/chips';
import PanelMenu from 'primevue/panelmenu';
import ScrollPanel from 'primevue/scrollpanel';
import SpeedDial from 'primevue/speeddial'
import ContextMenu from 'primevue/contextmenu'
import BlockUI from 'primevue/blockui';
import Badge from 'primevue/badge';
import ProgressSpinner from 'primevue/progressspinner';
import Checkbox from 'primevue/checkbox';
import InlineMessage from 'primevue/inlinemessage';
import Message from 'primevue/message';
import Textarea from 'primevue/textarea';
import TabMenu from 'primevue/tabmenu';
Vue.component("TabMenu", TabMenu);
Vue.component("Checkbox", Checkbox);
Vue.component("ScrollPanel", ScrollPanel);
Vue.component("v-otp-input", OtpInput);
Vue.component('InputText', InputText)
Vue.component('Dropdown', Dropdown)
Vue.component('InputMask', InputMask)
Vue.component('Calendar', Calendar)
Vue.component('AutoComplete', AutoComplete)
Vue.component('InputNumber', InputNumber)
Vue.component('Card', Card)
Vue.component('Button', Button)
Vue.component('Divider', Divider)
Vue.component('Dialog', Dialog)
Vue.component('ProgressBar', ProgressBar)
Vue.component('Toast', Toast)
Vue.component('OverlayPanel', OverlayPanel)
Vue.component('DataTable', DataTable)
Vue.component('Column', Column)
Vue.component('ColumnGroup', ColumnGroup)
Vue.component('Menubar', Menubar)
Vue.component('Toolbar', Toolbar)
Vue.component('Avatar', Avatar)
Vue.component('TieredMenu', TieredMenu)
Vue.component('Menu', Menu)
Vue.component('ImagePreview', ImagePreview)
Vue.component('Password', Password)
Vue.component('TabView', TabView)
Vue.component('TabPanel', TabPanel)
Vue.component('Row', Row)
Vue.directive('tooltip', Tooltip);
Vue.component('FileUpload', FileUpload)
Vue.component('MegaMenu', MegaMenu)
Vue.component('SelectButton', SelectButton)
Vue.component('RadioButton', RadioButton)
Vue.component('InputSwitch', InputSwitch)
Vue.component('MultiSelect', MultiSelect)
Vue.component('Chip', Chip)
Vue.component('Chips', Chips)
Vue.component('PanelMenu', PanelMenu)
Vue.component('InlineMessage', InlineMessage)
Vue.component('SpeedDial', SpeedDial)
Vue.component('ContextMenu', ContextMenu)
Vue.component('BlockUI', BlockUI)
Vue.component('Badge', Badge)
Vue.component('ProgressSpinner', ProgressSpinner)
Vue.component('Message', Message)
Vue.component('Textarea', Textarea)
Vue.component('Dialog', Dialog)

Vue.config.productionTip = false
import 'primevue/resources/primevue.min.css'  //core css
import 'primeicons/primeicons.css'  //icons
import 'primeflex/primeflex.css'
new Vue({
  router,
  PrimeVue,
  store,
  render: h => h(App)
}).$mount('#app')
