import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '../store/index'

import Gastos from '../components/Gastos/Gastos.vue'
import Ingresos from '../components/Ingresos/Ingresos.vue'
import Nomina from '../components/Nomina/Nomina.vue'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/Login', name: 'Login', component: Login, meta: { libre: true } },
  { path: '/', name: 'home', component: HomeView, meta: { Administrador: true } },
  { path: '/Gastos', name: 'Gastos', component: Gastos, meta: { Administrador: true } },
  { path: '/Ingresos', name: 'Ingresos', component: Ingresos, meta: { Administrador: true } },
  { path: '/Nomina', name: 'Nomina', component: Nomina, meta: { Administrador: true } },
  { path: '/about', name: 'about', component: () => import('../views/AboutView.vue') }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.libre)) {
    next()
  } else if (store.state.usuario && store.state.usuario.rol == 'Administrador') {
    if (to.matched.some(record => record.meta.Administrador)) {
      next()
    }
  } else {
    next({
      name: 'Login'
    })
  }
})
export default router
