<template>
    <div id="aqui">
        <a href="/" class="btn-flotante"><i class="mdi mdi-arrow-left-bold text-2xl  " style="color: white;"></i></a>
        <iframe class="iframe" src="https://auditor-gastos.contago.com.mx/" style="border: white;" allow="fullscreen"
            width="100%"></iframe>
    </div>
</template>
<script>

export default {
    name: 'HomeView',
    components: {
    },
    data() {
        return {
            url: ''
        }
    },
    methods: {

    },
    created() {

    }

}
</script>
<style >
html,
body,
div,
iframe {
    margin: 0;
    padding: 0;
    height: 100%;
}

iframe {
    display: block;
    width: 100%;
    border: none;
}

.btn-flotante {
    font-size: 18px;
    /* Cambiar el tamaño de la tipografia */
    text-transform: uppercase;
    /* Texto en mayusculas */
    font-weight: bold;
    /* Fuente en negrita o bold */
    color: #ffffff;
    /* Color del texto */
    border-radius: 5px;
    /* Borde del boton */
    letter-spacing: 2px;
    /* Espacio entre letras */
    background-color: #E91E63;
    /* Color de fondo */
    padding: 15px 15px;
    /* Relleno del boton */
    position: fixed;
    bottom: 40px;
    right: 40px;
    transition: all 300ms ease 0ms;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    z-index: 99;
}

.btn-flotante:hover {
    background-color: #2c2fa5;
    /* Color de fondo al pasar el cursor */
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
    transform: translateY(-7px);
}

@media only screen and (max-width: 600px) {
    .btn-flotante {
        font-size: 14px;
        padding: 12px 20px;
        bottom: 20px;
        right: 20px;
    }
}
</style>