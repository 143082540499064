<template>
  <div>
    <div style="height: auto;">
      <div style="width: 100%; height: 20px; background: #CC003D;">
      </div>
      <div style="width: 100%; height: 100px; background: black;">
        <div class="mb-3 pt-3 font-bold text-5xl">
          <span class="text--900" style="color: #ffffff;">Mi Auditor </span>
          <span class="text--600" style="color: #CC003D;">Sector Público</span><br>
        </div>

        <div class="font-bold" style="margin-top: -50px;">
          <span style="font-size: 16px; color: #ffffff;">{{ $store.state.usuario.rfc +
            ' | ' +
            $store.state.usuario.empresa
          }}</span>
        </div>
        <span>
        </span>
        <div style="text-align: right; margin-top: -135px; margin-right: 15px;">
          <template v-if="cerrandoSeion == false">
            <Button @click="salir()" icon="mdi mdi-logout" class="p-button-rounded"
              style="font-size: 22px; width: 45PX; height: 45PX; background: #CC003D;" />
          </template>
          <template v-if="cerrandoSeion == true">
            <ProgressSpinner style="width:50px;height:50px" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />
          </template>

        </div>
      </div>

      <Dialog :showHeader="false" :visible.sync="dialogGastos" :closeOnEscape="true" :modal="true"
        :containerStyle="{ width: '95vw' }">
        <iframe src="https://auditor-gastos.contago.com.mx/" style="border: white;" allow="fullscreen" width="100%"
          height="800px"></iframe>
      </Dialog>

      <div class="surface-section px-8 mx-8 py-4 md:px-6 lg:px-8 text-center">
        <img src="../assets/logo_contago.png" width="550px" alt="">
        <!-- <div class="mb-3 font-bold text-6xl">
        <span class="text-900">Mi Auditor </span>
        <span class="text--600" style="color: #E74747;">Sector Público</span>
      </div> -->

        <!-- <div class="text-700 mb-6">Ac turpis egestas maecenas pharetra convallis posuere morbi leo urna.</div> -->
        <div class="grid mt-8">
          <div class="col-12 md:col-4 mb-4 px-8">
            <span @click="abrirIngresos" class="p-3 shadow-6 mb-3 inline-block surface-card"
              style="border-radius: 10px; cursor: pointer;">
              <i class="mdi mdi-file-document-plus text-8xl text--500" style="color: #CC003D;"></i>
            </span>
            <div class="text-900 text-3xl mb-3 font-medium">Ingresos</div>
            <!-- <span class="text-700 line-height-3">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
              dolore eu fugiat nulla pariatur.</span> -->
          </div>
          <div class="col-12 md:col-4 mb-4 px-5">
            <span @click="abrirGastos" class="p-3 shadow-6 mb-3 inline-block surface-card"
              style="border-radius: 10px; cursor: pointer;">
              <i class="mdi mdi-file-document-minus text-8xl text--500" style="color: #CC003D;"></i>
            </span>
            <div class="text-900 text-3xl mb-3 font-medium">Gastos</div>
            <!-- <span class="text-700 line-height-3">Risus nec feugiat in fermentum posuere urna nec. Posuere sollicitudin
              aliquam ultrices sagittis.</span> -->
          </div>
          <div class="col-12 md:col-4 mb-4 px-5">
            <span @click="abrirNomina" class="p-3 shadow-6 mb-3 inline-block surface-card"
              style="border-radius: 10px; cursor: pointer;">
              <i class="mdi mdi-account-cash text-8xl text--500" style="color: #CC003D;"></i>
            </span>
            <div class="text-900 text-3xl mb-3 font-medium">Nómina</div>
            <!-- <span class="text-700 line-height-3">Ornare suspendisse sed nisi lacus sed viverra tellus. Neque volutpat ac
              tincidunt vitae semper.</span> -->
          </div>
        </div>
      </div>
      <div class="footer" style="width: 100%; height: 30px; background: black;">

      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import axios from "axios";

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  },
  data() {
    return {
      dialogIngresos: false,
      dialogGastos: false,
      loading: false,
      cerrandoSeion: false
    }
  },
  methods: {
    abrirIngresos() {
      const token = localStorage.getItem('token');
      document.cookie = `seguroContago=${token}; path=/; domain=.contago.com.mx`;

      this.$router.push({ name: 'Ingresos' })
    },
    abrirGastos() {
      const token = localStorage.getItem('token');
      document.cookie = `seguroContago=${token}; path=/; domain=.contago.com.mx`;

      this.$router.push({ name: 'Gastos' })
    },
    abrirNomina() {
      // window.open("_blank", "ventana1", "menubar=0,width=1800,height=1800,scrollbars=NO,")

      const token = localStorage.getItem('token');
      document.cookie = `seguroContago=${token}; path=/; domain=.contago.com.mx`;

      this.$router.push({ name: 'Nomina' })
    },

    async salir() {
      this.cerrandoSeion = true;
      let respuesta;
      try {
        respuesta = await axios.put(
          "https://api-framework.contago.com.mx/api/Usuarios/actualizaSesion/" +
          this.$store.state.usuario.nombre +
          "/INACTIVA"
        );
        this.$store.dispatch("salir");

        //INICIALIZAMOS LOS CATALOGOS
        let correo = {
          asunto: '',
          mensaje: '',
          emisor: {},
          archivos: [],
          receptores: [],
        }
        let empresa = {
          logo: {
            extencion: '',
            base64: '',
          },
          idEmpresa: 0,
          rfc: '',
          domicilioFiscal: '',
          nombre: '',
          regimenFiscal: {}
        }

        this.$store.state.empresaStore = { ...empresa }
        this.$store.state.correoStore = { ...correo }
      } catch (err) {
        console.log(err);
      }
      this.cerrandoSeion = false;
    },
  }

}
</script>
<style>
.p-dialog .p-dialog-content {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  padding: 0 0 0;
}

.footer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  height: 20px;
  width: 100%;
  background: #000000;
}

.dialogoTransparente .p-dialog .p-dialog-content {
  background: transparent;
}
</style>